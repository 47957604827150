import { render, staticRenderFns } from "./PiHole.vue?vue&type=template&id=3f2b65c6&scoped=true&"
import script from "./PiHole.vue?vue&type=script&lang=js&"
export * from "./PiHole.vue?vue&type=script&lang=js&"
import style0 from "./PiHole.vue?vue&type=style&index=0&id=3f2b65c6&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f2b65c6",
  null
  
)

export default component.exports